import React, {useEffect, useState} from 'react';
import '../css/pages/landing.scss';
import SubscribePopupWrapper from '../components/subscribe/SubscribePopup';
import {isBrowser} from '../utils/articleUtil';
import queryString from 'query-string';
import Helmet from 'react-helmet';

function Landing () {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (isBrowser()) {
      window.streamData = window.streamData || {};
      if (window.location && window.location.search) {
        const query = queryString.parse(window.location.search);
        if (query.subscribe) {
          setShowPopup(query.subscribe === 'true');
        }
      }

      window.dataLayer = window.dataLayer || [];
      const eventData = {
        'event': 'pageview',
        'hostname': window.location.hostname,
        'url': window.location.href,
        'page_Path': window.location.pathname
      };
      window.dataLayer.push(eventData);
    }
  }, []);

  const products = [
    {
      img: 'p2.png',
      url: 'https://www.awin1.com/cread.php?s=3038141&v=21941&q=427809&r=892181',
      imgUrl: 'https://www.awin1.com/cshow.php?s=3038141&v=21941&q=427809&r=892181'
    },
    {
      img: 'p4.png',
      url: 'https://www.awin1.com/cread.php?s=2926522&v=24407&q=417176&r=892181',
      imgUrl: 'https://www.awin1.com/cshow.php?s=2926522&v=24407&q=417176&r=892181'
    },
    {
      img: 'p3.png',
      url: 'https://www.awin1.com/cread.php?s=2926501&v=8998&q=417174&r=892181',
      imgUrl: 'https://www.awin1.com/cshow.php?s=2926501&v=8998&q=417174&r=892181'
    },
    {
      img: 'p1.png',
      url: 'https://www.awin1.com/cread.php?s=607835&v=4445&q=300139&r=892181',
      imgUrl: 'https://www.awin1.com/cshow.php?s=607835&v=4445&q=300139&r=892181'
    }
  ];

  return (
    <main className="wrapper main-data-container landing-page" role="main" data-datocms-noindex>
      <Helmet>
        <script src="/gtm.js" type="text/javascript" async={true}/>
      </Helmet>
      <div className="md:hidden text-center">
        <img className="my-4 h-[40px]" src="/images/lp/logo.svg"/>
      </div>
      <div className="max-h-[400px] md:max-h-[350px] overflow-hidden text-center">
        <picture className="block">
          <source media="(max-width:767px)" srcSet="/images/lp/1x.jpeg"/>
          <source media="(min-width:767px)" srcSet="/images/lp/1.png"/>
          <img className="w-full" src="/images/lp/1x.jpeg"/>
        </picture>
      </div>
      <div className="relative md:mt-[-85px]">
        <div className="flex items-center">
          <div className="md:leading-[23px] relative md:top-[40px] text-base md:text-lg p-4 md:p-8">
            <h1 className="font-bold text-base md:text-lg">Hello, fellow quadragenarian*</h1>
            <p>Would you like to become a part of a pro-age platform that connects, celebrates, and
              rewards people (like us) over the age of 50?</p>

            <p>AgeGroup has a vision to create a next-generation wellness platform for over-50s in
              the UK. We are constantly on the lookout for premium brands that deliver special
              deals, discounts and products for those who have the money and the time to enjoy
              them.</p>

            This is how it works - by joining us, you enable us to use the power of the group to
            negotiate better deals and offers for the over-50 community. We will find new
            opportunities across multiple markets at no cost to you. There are no strings attached.
          </div>
          <img className="hidden md:block w-[322px] mr[0]" src="/images/lp/2.png"/>
        </div>
        <div className="lp3 flex h-[240px] xxs:h-[220px] lg:h-[415px]">
          <div className="xxs:ml-[35%] lg:ml-[50%] xxs:mt-[7px] lg:mt-[127px] p-8 xxs:p-0 xxs:pr-2">
            <h1
              className="text-2xl xxs:text-3xl md:text-4xl font-bold leading-snug md:leading-relaxed">I’m
              interested How do I join?</h1>
            <p className="md:text-lg leading-normal md:leading-relaxed">Just reply to this email to
              subscribe to our free mailing list.</p>
            <p className="md:text-lg leading-normal md:leading-relaxed">Don’t waste any time! You
              are not getting any younger :)</p>
          </div>
        </div>
        <div className="text-white bg-purple-950 text-center p-4">
          <h1 className="text-base md:text-4xl font-bold">Age comes with benefits – As a welcome
            gift,<br/>we are offering you a <br className="md:hidden"/> <span
              className="text-yellow-500">10% discount</span> on the following items</h1>
          <div className="flex flex-wrap md:flex-nowrap items-center justify-center md:p-4 mb-4">
            {products.map((product, i) => {
              return (
                <div key={`discount-${i}`} className="md:m-2 w-[50%] md:w-[unset]">
                  <a rel="sponsored" href={product.url}>
                    <img className="w-full" src={`/images/lp/${product.img}`}/>
                  </a>
                  <a
                    className="bg-yellow-500 hidden md:block text-black rounded-lg py-3 text-2xl font-bold mt-4"
                    rel="sponsored" href={product.url}>
                    Click Here
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="bg-blue-950 text-base md:text-2xl lg:text-4xl font-bold text-white flex md:mb-[-50px] pt-4 md:pt-0 overflow-hidden">
          <div className="md:w-[60%] mt-4 md:mt-8 ml-4">
            <h1 className="text-base md:text-2xl lg:text-4xl">Feel free to discover more great
              content from AgeTimes publishing </h1>
            <div className="md:ml-4 mt-4 md:mt-8 lg:mt-16">
              <div>
                <img className="w-[27px] md:w-[53px] lg:w-[63px] mr-1" src="/images/lp/PT.png"/>
                <span>Pension Times</span>
              </div>
              <div className="mt-2">
                <img className="w-[27px] md:w-[53px] lg:w-[63px] mr-1" src="/images/lp/HT.png"/>
                <span>Health Times</span>
              </div>
              <div className="mt-2">
                <img className="w-[27px] md:w-[53px] lg:w-[63px] mr-1" src="/images/lp/AT.png"/>
                <span>Age Times</span>
              </div>
            </div>
          </div>
          <picture
            className="mr-[-52px] xxs:mr-0 h-[260px] xxs:h-full min-w-[180px] md:min-w-[300px] lg:min-w-[564px]">
            <source media="(max-width:767px)" srcSet="/images/lp/4x.jpeg"/>
            <source media="(min-width:767px)" srcSet="/images/lp/4.png"/>
            <img className="w-full relative md:top-[-50px]" src="/images/lp/4x.jpeg"/>
          </picture>
        </div>
        <div className="bg-gray-950 relative text-center pb-8">
          <button onClick={() => setShowPopup(true)}
                  className="subscribe subscribe-landing bg-yellow-500 text-base md:text-4xl font-bold w-full xxs:w-4/5 m-auto xxs:rounded-lg relative xxs:top-[-50%] p-2 md:p-6 xxs:translate-y-[-50%] cursor-pointer">Join
            our mailing list and start enjoying great benefits!
          </button>
          <p className="text-white text-base md:text-3xl mt-4 xxs:mt-0">Free forever - cancel
            anytime - no hidden charges.</p>
        </div>
      </div>
      {showPopup && <SubscribePopupWrapper onPopupClose={() => {
        setShowPopup(false);
      }}/>}
    </main>
  );
}

export default Landing;
